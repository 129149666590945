<template>
	<div class="animated fadeIn p-4">
		<div
			v-if="!['xs', 'sm', 'md', 'lg'].includes(windowWidth)"
			class="row">
			<div class="col">
				<b><h3>{{ translate('mtd_comparison') }}</h3></b>
				<b-card>
					<template v-if="!loading && hasMtdComparisonData">
						<div class="row">
							<div class="col-12 col-lg-4 h-100">
								<p class="pb-0 mb-0">
									<b>{{ translate('mtd') }}</b>
								</p>
								<p
									class="pb-2 mb-4"
									style="font-size:11px; font-style: italic;">
									{{ `(${$moment(mtdComparision.current_start_date.date).format(dateFormat)} - ${$moment(mtdComparision.current_end_date.date).format(dateFormat)})` }}
								</p>
								<p />
								<hr>
								<p class="pb-0 mb-0">
									{{ mtdComparision.current }}
								</p>
							</div>
							<div class="col-12 col-lg-4 h-100">
								<p class="pb-0 mb-0">
									<b>{{ translate('previous_mtd') }}</b>
								</p>
								<p
									class="pb-0 mb-0"
									style="font-size:11px; font-style: italic;">
									{{ `(${$moment(mtdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(mtdComparision.previous_end_date.date).format(dateFormat)})` }}
								</p>
								<p
									class="pb-0 mb-0"
									style="font-size:11px; font-style: italic;">
									{{ ytdComparision.last_year_month }}
								</p>
								<hr>
								<p class="pb-0 mb-0">
									{{ mtdComparision.previous }}
								</p>
							</div>
							<div class="col-12 col-lg-4 h-100">
								<p class="pb-0 mb-3">
									<b>{{ translate('difference') }}</b>
								</p>
								<p
									class="pb-0 mb-0"
									style="opacity:0;font-size:11px; font-style: italic;">
									{{ `(${$moment(mtdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(mtdComparision.previous_end_date.date).format(dateFormat)})` }}
								</p>
								<hr>
								<p class="pb-0 mb-0">
									<span
										:class="mtdComparision.positive ? 'badge badge-green' : 'badge badge-red'"
										class="badge w-100"
										style="font-size:12px;"><b>{{ mtdComparision.difference }}</b></span>
								</p>
							</div>
						</div>
					</template>
					<is-loading
						:loading-label="translate('loading')"
						:loading="loading" />
				</b-card>
			</div>
			<div class="col h-100">
				<b><h3>{{ translate('ytd_comparison') }}</h3></b>
				<b-card>
					<template v-if="!loading && hasYtdComparisonData">
						<div class="row">
							<div class="col-12 col-lg-4 h-100">
								<p class="pb-0 mb-0">
									<b>{{ translate('ytd', { year: this.$moment(ytdComparision.current_start_date.date).format(yearFormat) }) }}</b>
								</p>
								<p
									class="pb-2 mb-4"
									style="font-size:11px; font-style: italic;">
									{{ `(${$moment(ytdComparision.current_start_date.date).format(dateFormat)} - ${$moment(ytdComparision.current_end_date.date).format(dateFormat)})` }}
								</p>
								<hr>
								<p class="pb-0 mb-0">
									{{ ytdComparision.current }}
								</p>
							</div>
							<div class="col-12 col-lg-4 h-100">
								<p class="pb-0 mb-0">
									<b>{{ translate('ytd', { year: this.$moment(ytdComparision.previous_start_date.date).format(yearFormat) }) }}</b>
								</p>
								<p
									class="pb-2 mb-4"
									style="font-size:11px; font-style: italic;">
									{{ `(${$moment(ytdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(ytdComparision.previous_end_date.date).format(dateFormat)})` }}
								</p>
								<hr>
								<p class="pb-0 mb-0">
									{{ ytdComparision.previous }}
								</p>
							</div>
							<div class="col-12 col-lg-4 h-100">
								<p class="pb-0 mb-0">
									<b>{{ translate('difference') }}</b>
								</p>
								<p
									class="pb-2 mb-4"
									style="opacity:0;font-size:11px; font-style: italic;">
									{{ `(${$moment(ytdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(ytdComparision.previous_end_date.date).format(dateFormat)})` }}
								</p>
								<hr>
								<p class="pb-0 mb-0">
									<span
										:class="ytdComparision.positive ? 'badge badge-green' : 'badge badge-red'"
										class="badge w-100"
										style="font-size:12px;"><b>{{ ytdComparision.difference }}</b></span>
								</p>
							</div>
						</div>
					</template>
					<is-loading
						:loading-label="translate('loading')"
						:loading="loading" />
				</b-card>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<template v-if="['xs', 'sm', 'md', 'lg'].includes(windowWidth)">
					<b><h3>{{ translate('mtd_comparison') }}</h3></b>
					<b-card>
						<template v-if="!loading && hasMtdComparisonData">
							<div class="row">
								<div class="col-12 col-lg-4 h-100">
									<p class="pb-0 mb-0">
										<b>{{ translate('mtd') }}</b>
									</p>
									<p
										class="pb-0 mb-0"
										style="font-size:11px; font-style: italic;">
										{{ `(${$moment(mtdComparision.current_start_date.date).format(dateFormat)} - ${$moment(mtdComparision.current_end_date.date).format(dateFormat)})` }}
									</p>
									<p class="pb-2 mb-4">
										{{ mtdComparision.current }}
									</p>
									<hr>
								</div>
								<div class="col-12 col-lg-4 h-100">
									<p class="pb-0 mb-0">
										<b>{{ translate('previous_mtd') }}</b>
									</p>
									<p
										class="pb-0 mb-0"
										style="font-size:11px; font-style: italic;">
										{{ `(${$moment(mtdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(mtdComparision.previous_end_date.date).format(dateFormat)})` }}
									</p>
									<p
										class="pb-0 mb-0"
										style="font-size:11px; font-style: italic;">
										{{ ytdComparision.last_year_month }}
									</p>
									<p class="pb-0 mb-0">
										{{ mtdComparision.previous }}
									</p>
									<hr>
								</div>
								<div class="col-12 col-lg-4 h-100">
									<p class="pb-0 mb-0">
										<b>{{ translate('difference') }}</b>
									</p>
									<p
										class="pb-0 mb-0"
										style="opacity:0;font-size:11px; font-style: italic;">
										{{ `(${$moment(mtdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(mtdComparision.previous_end_date.date).format(dateFormat)})` }}
									</p>
									<p class="pb-2 mb-4">
										<span
											:class="mtdComparision.positive ? 'badge badge-green' : 'badge badge-red'"
											class="badge w-100"
											style="font-size:12px;"><b>{{ mtdComparision.difference }}</b></span>
									</p>
									<hr>
								</div>
							</div>
						</template>
						<is-loading
							:loading-label="translate('loading')"
							:loading="loading" />
					</b-card>
				</template>
				<b><h3 class="mt-4">{{ translate('monthly_sales') }}</h3></b>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									{{ translate('month') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('sales') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('previous_month') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('diff_previous') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasMonthlySalesData">
							<tr
								v-for="(item, index) in monthlySales"
								:key="index">
								<td class="align-middle text-nowrap">
									{{ `${translate('month_' + item.month)} ${item.year}` }}
								</td>
								<td class="align-middle text-right">
									{{ item.format_current_sales }}
								</td>
								<td class="align-middle text-right">
									{{ item.format_previous_sales }}
								</td>
								<td class="align-middle">
									<span
										:class="item.positive ? 'badge badge-green' : 'badge badge-red'"
										class="badge w-100"
										style="font-size:12px;"><b>{{ item.difference }}</b></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:loading="loading"
					:has-data="hasYearlySalesData" />
			</div>
			<div class="col">
				<template v-if="['xs', 'sm', 'md', 'lg'].includes(windowWidth)">
					<b><h3>{{ translate('ytd_comparison') }}</h3></b>
					<b-card>
						<template v-if="!loading && hasYtdComparisonData">
							<div class="row">
								<div class="col-12 col-lg-4 h-100">
									<p class="pb-0 mb-0">
										<b>{{ translate('ytd', { year: this.$moment(ytdComparision.current_start_date.date).format(yearFormat) }) }}</b>
									</p>
									<p
										class="pb-0 mb-0"
										style="font-size:11px; font-style: italic;">
										{{ `(${$moment(ytdComparision.current_start_date.date).format(dateFormat)} - ${$moment(ytdComparision.current_end_date.date).format(dateFormat)})` }}
									</p>
									<p class="pb-2 mb-4">
										{{ ytdComparision.current }}
									</p>
									<hr>
								</div>
								<div class="col-12 col-lg-4 h-100">
									<p class="pb-0 mb-0">
										<b>{{ translate('ytd', { year: this.$moment(ytdComparision.previous_start_date.date).format(yearFormat) }) }}</b>
									</p>
									<p
										class="pb-0 mb-0"
										style="font-size:11px; font-style: italic;">
										{{ `(${$moment(ytdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(ytdComparision.previous_end_date.date).format(dateFormat)})` }}
									</p>
									<p class="pb-2 mb-4">
										{{ ytdComparision.previous }}
									</p>
									<hr>
								</div>
								<div class="col-12 col-lg-4 h-100">
									<p class="pb-0 mb-0">
										<b>{{ translate('difference') }}</b>
									</p>
									<p
										class="pb-0 mb-0"
										style="opacity:0;font-size:11px; font-style: italic;">
										{{ `(${$moment(ytdComparision.previous_start_date.date).format(dateFormat)} - ${$moment(ytdComparision.previous_end_date.date).format(dateFormat)})` }}
									</p>
									<p class="pb-2 mb-4">
										<span
											:class="ytdComparision.positive ? 'badge badge-green' : 'badge badge-red'"
											class="badge w-100"
											style="font-size:12px;"><b>{{ ytdComparision.difference }}</b></span>
									</p>
									<hr>
								</div>
							</div>
						</template>
						<is-loading
							:loading-label="translate('loading')"
							:loading="loading" />
					</b-card>
				</template>
				<b><h3 class="mt-4">{{ translate('year_sales') }}</h3></b>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									{{ translate('month') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('sales') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('previous_year') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('diff_previous') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasYearlySalesData">
							<tr
								v-for="(item, index) in ydtSales"
								:key="index">
								<td class="align-middle text-nowrap">
									{{ `${translate('month_' + item.month)} ${item.year}` }}
								</td>
								<td class="align-middle text-right">
									{{ item.format_current_sales }}
								</td>
								<td class="align-middle text-right">
									{{ item.format_previous_sales }}
								</td>
								<td class="align-middle">
									<span
										:class="item.positive ? 'badge badge-green' : 'badge badge-red'"
										class="badge w-100"
										style="font-size:12px;"><b>{{ item.difference }}</b></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:loading="loading"
					:has-data="hasYearlySalesData" />
			</div>
		</div>
	</div>
</template>
<script>
import {
	Report as messages, Grids, PaymentMethods, Countries, Comparison, Dates,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import {
	YMD_FORMAT, YEAR_FORMAT,
} from '@/settings/Dates';
import Sales from '@/util/Report';

export default {
	name: 'SalesComparison',
	messages: [messages, Grids, PaymentMethods, Countries, Comparison, Dates],
	mixins: [WindowSizes],
	data() {
		return {
			salesComparison: new Sales(),
			dateFormat: YMD_FORMAT,
			yearFormat: YEAR_FORMAT,
		};
	},
	computed: {
		loading() {
			return !!this.salesComparison.data.loading;
		},
		errors() {
			return this.salesComparison.data.errors;
		},
		pagination() {
			return this.salesComparison.data.pagination;
		},
		monthlySales() {
			try {
				const monthlySales = this.salesComparison.data.response.data.response.monthly_sales;
				return monthlySales;
			} catch (error) {
				return [];
			}
		},
		hasMonthlySalesData() {
			return !!this.monthlySales.length;
		},
		ydtSales() {
			try {
				const ytdSales = this.salesComparison.data.response.data.response.ytd_sales;
				return ytdSales;
			} catch (error) {
				return [];
			}
		},
		hasYearlySalesData() {
			return !!this.ydtSales.length;
		},
		mtdComparision() {
			try {
				const mtdComparision = this.salesComparison.data.response.data.response.mtd_comparision;
				return mtdComparision;
			} catch (error) {
				return {};
			}
		},
		hasMtdComparisonData() {
			return !!Object.keys(this.mtdComparision).length;
		},
		ytdComparision() {
			try {
				const ytdComparision = this.salesComparison.data.response.data.response.ytd_comparision;
				return ytdComparision;
			} catch (error) {
				return {};
			}
		},
		hasYtdComparisonData() {
			return !!Object.keys(this.ytdComparision).length;
		},
	},
	mounted() {
		this.salesComparison.getSalesComparison();
	},
};
</script>
